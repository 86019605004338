import logo from './images/logo.svg';
import people from './images/people.png';
import scroll from './images/scroll.png';
import infoIcon from './images/info-icon.png';
import communityIcon from './images/community-icon.png';
import convoIcon from './images/convo-icon.png';
import Mailchimp from 'react-mailchimp-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export default function App() {
  return (
    <div>
      <div className="page main-page">
        <img className="logo" src={logo} alt="logo" />
        <h1 className="tagline">Welcome to Transition Space!</h1>
        <img className="people" src={people} alt="people" />
        <img className="scroll" src={scroll} alt="scroll" />
      </div>
      <div className="page desc-page">
        <p className="desc-text">
          We are an online resource hub dedicated to helping youth navigate the
          adult disability system.
        </p>

        <h2 className="how-we-help">How do we help?</h2>
        <div className="info-boxes-container">
          <div className="info-boxes">
            <img className="info-box-icons" src={infoIcon} alt="info icon" />
            <p className="info-box-text">
              Provide information on components of the disability system,
              including finances, healthcare, recreation and housing services
            </p>
          </div>
          <div className="info-boxes">
            <img
              className="info-box-icons"
              src={communityIcon}
              alt="community icon"
            />
            <p className="info-box-text">
              Share personalized community resources to connect youth to the
              adult disability system
            </p>
          </div>
          <div className="info-boxes">
            <img className="info-box-icons" src={convoIcon} alt="convo icon" />
            <p className="info-box-text">
              Facilitate conversations between youth, families, and care
              providers to support a successful transition to adulthood
            </p>
          </div>
        </div>
      </div>

      <div className="survey-page">
        <div className="survey-box">
          <h2 className="survey-header">
            Sign up for our Newsletter & be the first to know when we launch!
          </h2>

          <Mailchimp
            crossorigin="anonymous"
            action="https://gmail.us1.list-manage.com/subscribe/post?u=7f4cc2458c86ebb81f10283d4&amp;id=6c76d0b843"
            fields={[
              {
                name: 'NAME',
                placeholder: 'Name',
                type: 'name',
                required: false,
              },
              {
                name: 'EMAIL',
                placeholder: 'Email (required)',
                type: 'email',
                required: true,
              },
            ]}
            messages={{
              sending: 'Sending...',
              success: 'Thank you for subscribing!',
              error: 'An unexpected internal error has occurred.',
              empty:
                'Please ensure to provide and email and that it is correctly formatted.',
              duplicate: 'Too many subscribe attempts for this email address',
              button: 'Submit',
            }}
            className="mailchimp-form"
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="social-media">
          <h2 className="social-media-header">
            Connect with us on Social Media!
          </h2>
          <div className="social-media-icons">
            <a
              className="social-media-links"
              href="https://www.facebook.com/Transition-Space-100852845276592"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="social-media-icon"
                size="3x"
              />
            </a>
            <a
              className="social-media-links"
              href="https://www.instagram.com/transition.space"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="social-media-icon"
                size="3x"
              />
            </a>
            <a
              className="social-media-links"
              href="https://www.linkedin.com/company/transitionspace"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="social-media-icon"
                size="3x"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer">
        <img className="logo" src={logo} alt="logo" />
      </div>
    </div>
  );
}
